<template>
    <div>
        <ValidationObserver ref="form">
            <form style="border-radius: 15px; padding: 2rem">
                <!-- Company information re-design -->
                <div class="content-company-info">
                    <div class="content-company-info-form mt-5">
                        <div v-if="!isNext">
                            <div class="d-flex justify-content-between bottom-30">
                                <!-- <div class="d-flex justify-content-end align-items-center section-photo-upload w-48">
                                    <div class="d-flex flex-column pe-5">
                                        <span class="text-hint-main">Upload Tips</span>
                                        <span class="text-sub-hint">Avoid color backgrounds.</span>
                                        <span class="text-sub-hint">Best resolution 320px (width) 110px (Height)</span>
                                    </div>
                                    <div class="block-logo-upload">
                                        <div class="image-container" style="">
                                            <div class="overlay">
                                                <div class="upload" @click="selectFile">
                                                    <span class="material-icons-outlined">file_upload</span> &nbsp;
                                                    Upload
                                                </div>
                                                <div>
                                                    <input
                                                        id="fileid"
                                                        type="file"
                                                        name="upload"
                                                        ref="upload"
                                                        @change="uploadImage($event)"
                                                        hidden
                                                        required
                                                    />
                                                </div>
                                            </div>
                                            <div v-if="image" style="height: 100%">
                                                <img :src="image" alt="" class="img-wrap" />
                                            </div>
                                        </div>
                                    </div>
                                </div> -->
                                <Input
                                    class="w-48"
                                    inputClass="company-form-field"
                                    type="text"
                                    name="company name"
                                    id="company_name"
                                    placeholder="Company Name"
                                    v-model="record_data.company_name"
                                    label="Company Name"
                                    required
                                />
                            </div>
                            <div class="d-flex justify-content-between bottom-30">
                                <Input
                                    class="w-48"
                                    inputClass="company-form-field"
                                    type="text"
                                    name="company email"
                                    id="company_email"
                                    rules="email"
                                    placeholder="Company Email"
                                    v-model="record_data.company_email"
                                    label="Company Email"
                                    required
                                />
                                <Input
                                    class="w-48"
                                    inputClass="company-form-field"
                                    type="text"
                                    name="phone"
                                    id="phone"
                                    rules="phone"
                                    placeholder="000-000-0000"
                                    v-model="record_data.phone"
                                    label="Phone Number"
                                    required
                                />
                            </div>
                            <div class="d-flex justify-content-between bottom-30">
                                <Input
                                    class="w-48"
                                    inputClass="company-form-field"
                                    type="text"
                                    name="company website"
                                    id="company_website"
                                    rules="ValidateUrl"
                                    placeholder="Company Website (URL)"
                                    v-model="record_data.company_website"
                                    label="Company Website (URL)"
                                    required
                                />
                                <TextArea
                                    class="w-48"
                                    inputClass="company-form-field"
                                    type="text"
                                    id="address"
                                    name="address"
                                    :rows="2"
                                    :cols="100"
                                    label="Address"
                                    v-model="record_data.address"
                                    :limit="4000"
                                    required
                                />
                            </div>
                            <div class="d-flex justify-content-between bottom-30">
                                <div class="flex colx2 space-between w-47">
                                    <Input
                                        type="text"
                                        name="city"
                                        id="city"
                                        placeholder="City"
                                        v-model="record_data.city"
                                        label="City"
                                        class="field-container w-49"
                                        inputClass="field-city"
                                        required
                                    />
                                    <UsStates
                                        v-model="record_data.state"
                                        id="state"
                                        name="state"
                                        label="State"
                                        placeholder="-------- Select --------"
                                        class="field-container w-49"
                                    />
                                </div>
                                <Input
                                    class="w-48"
                                    inputClass="company-form-field"
                                    type="text"
                                    name="zip"
                                    id="zip"
                                    placeholder="Zip Code"
                                    v-model="record_data.zip"
                                    label="Zip Code"
                                    required
                                />
                            </div>
                            <div class="d-flex justify-content-between bottom-40">
                                <div class="w-48">
                                    <label>Sensitive Screen Timeout*</label>
                                    <Multiselect
                                        id="sensitiveScreenTimeout"
                                        track-by="id"
                                        label="value"
                                        class="position-relative"
                                        placeholder="Select timeout"
                                        v-model="sensitiveScreenTimeout"
                                        :options="sensitiveScreenTimeoutList"
                                        :searchable="true"
                                        :allow-empty="false"
                                        :show-labels="false"
                                        @input="record_data.sensitiveScreenTimeout = $event.id"
                                    />
                                </div>
                                <div class="w-48">
                                    <label>Sensitive Screen Inactivity Timeout*</label>
                                    <Multiselect
                                        id="sensitiveAlertTimeout"
                                        track-by="id"
                                        label="value"
                                        class="position-relative"
                                        placeholder="Select timeout"
                                        v-model="sensitiveAlertTimeout"
                                        :options="sensitiveAlertTimeoutList"
                                        :searchable="true"
                                        :allow-empty="false"
                                        :show-labels="false"
                                        @input="record_data.sensitiveAlertTimeout = $event.id"
                                    />
                                </div>
                            </div>
                        </div>
                        <div v-else class="mx-4" style="display: flex; flex-direction: column; gap: 4rem">
                            <div>
                                <label for="kiosk">
                                    <h3 style="font-weight: 700">Kiosk Color Scheme*</h3>
                                </label>
                                <div class="d-flex flex-wrap justify-content-between" style="gap: 1%">
                                    <div class="d-grid color-box" style="margin: 1.2rem 0rem">
                                        <div class="m-4">
                                            <div class="d-flex" style="gap: 1%">
                                                <label for="option2" style="font-weight: bold"
                                                    >Primary Color Option</label
                                                >
                                            </div>
                                            <label class="d-flex py-2" style="gap: 1%" for="customized_color">
                                                <div class="w-25">
                                                    <input
                                                        type="color"
                                                        name=""
                                                        id=""
                                                        v-model="record_data.kiosk_colors.primary_colors[0]"
                                                        class="w-100"
                                                        @change="selectCustomColorScheme($event, 0, 'kiosk')"
                                                    />
                                                </div>
                                                <div class="w-25">
                                                    <input
                                                        type="color"
                                                        name=""
                                                        id=""
                                                        v-model="record_data.kiosk_colors.primary_colors[1]"
                                                        class="w-100"
                                                        @change="selectCustomColorScheme($event, 1, 'kiosk')"
                                                    />
                                                </div>
                                                <div class="w-25">
                                                    <input
                                                        type="color"
                                                        name=""
                                                        id=""
                                                        v-model="record_data.kiosk_colors.primary_colors[2]"
                                                        class="w-100"
                                                        @change="selectCustomColorScheme($event, 2, 'kiosk')"
                                                    />
                                                </div>
                                                <div class="w-25">
                                                    <input
                                                        type="color"
                                                        name=""
                                                        id=""
                                                        v-model="record_data.kiosk_colors.primary_colors[3]"
                                                        class="w-100"
                                                        @change="selectCustomColorScheme($event, 3, 'kiosk')"
                                                    />
                                                </div>
                                            </label>
                                            <label class="d-flex py-2 w-100" style="gap: 1%" for="default">
                                                <div
                                                    class="d-flex align-items-center w-25 justify-content-center"
                                                    style="font-weight: 600; color: #000; font-size: 12px"
                                                >
                                                    Primary Color
                                                </div>
                                                <div
                                                    class="d-flex align-items-center w-25 justify-content-center"
                                                    style="font-weight: 600; color: #000; font-size: 12px"
                                                >
                                                    Secondary Color
                                                </div>
                                                <div
                                                    class="d-flex align-items-center w-25 justify-content-center"
                                                    style="font-weight: 600; color: #000; font-size: 12px"
                                                >
                                                    Button Color
                                                </div>
                                                <div
                                                    class="d-flex align-items-center w-25 justify-content-center"
                                                    style="font-weight: 600; color: #000; font-size: 12px"
                                                >
                                                    Button Text Color
                                                </div>
                                            </label>
                                        </div>
                                    </div>
                                    <div class="d-grid color-box" style="margin: 1.2rem 0rem">
                                        <div class="m-4">
                                            <div class="d-flex" style="gap: 1%">
                                                <label for="option2" style="font-weight: bold"
                                                    >Secondary Color Option</label
                                                >
                                            </div>

                                            <label class="d-flex py-2" style="gap: 1%" for="customized_color">
                                                <div class="w-25">
                                                    <input
                                                        type="color"
                                                        name=""
                                                        id=""
                                                        v-model="record_data.kiosk_colors.secondary_colors[0]"
                                                        class="w-100"
                                                        @change="selectCustomColorScheme($event, 0, 'kiosk')"
                                                    />
                                                </div>
                                                <div class="w-25">
                                                    <input
                                                        type="color"
                                                        name=""
                                                        id=""
                                                        v-model="record_data.kiosk_colors.secondary_colors[1]"
                                                        class="w-100"
                                                        @change="selectCustomColorScheme($event, 1, 'kiosk')"
                                                    />
                                                </div>
                                                <div class="w-25">
                                                    <input
                                                        type="color"
                                                        name=""
                                                        id=""
                                                        v-model="record_data.kiosk_colors.secondary_colors[2]"
                                                        class="w-100"
                                                        @change="selectCustomColorScheme($event, 2, 'kiosk')"
                                                    />
                                                </div>
                                                <div class="w-25">
                                                    <input
                                                        type="color"
                                                        name=""
                                                        id=""
                                                        v-model="record_data.kiosk_colors.secondary_colors[3]"
                                                        class="w-100"
                                                        @change="selectCustomColorScheme($event, 3, 'kiosk')"
                                                    />
                                                </div>
                                            </label>
                                            <label class="d-flex py-2 w-100" style="gap: 1%" for="default">
                                                <div
                                                    class="d-flex align-items-center w-25 justify-content-center"
                                                    style="font-weight: 600; color: #000; font-size: 12px"
                                                >
                                                    Primary Color
                                                </div>
                                                <div
                                                    class="d-flex align-items-center w-25 justify-content-center"
                                                    style="font-weight: 600; color: #000; font-size: 12px"
                                                >
                                                    Secondary Color
                                                </div>
                                                <div
                                                    class="d-flex align-items-center w-25 justify-content-center"
                                                    style="font-weight: 600; color: #000; font-size: 12px"
                                                >
                                                    Button Color
                                                </div>
                                                <div
                                                    class="d-flex align-items-center w-25 justify-content-center"
                                                    style="font-weight: 600; color: #000; font-size: 12px"
                                                >
                                                    Button Text Color
                                                </div>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <label for="kiosk">
                                    <h3 style="font-weight: 700">Mobex Health Home Color Scheme*</h3>
                                </label>
                                <div class="d-flex flex-wrap justify-content-between" style="gap: 1%">
                                    <div class="d-grid color-box" style="margin: 1.2rem 0rem">
                                        <div class="m-4">
                                            <div class="d-flex" style="gap: 1%">
                                                <label for="option2" style="font-weight: bold"
                                                    >Portal Color Options</label
                                                >
                                            </div>
                                            <label class="flex py-2 wrap" for="customized_color">
                                                <div
                                                    class="d-flex align-items-center w-25 flex-column text-center gap-2"
                                                >
                                                    <input
                                                        type="color"
                                                        name="main_background_color"
                                                        v-model="
                                                            record_data.mobexhealthhome_colors.provider_color
                                                                .provider_main_background_color
                                                        "
                                                        class="w-100"
                                                        @change="
                                                            selectCustomColorScheme(
                                                                $event,
                                                                0,
                                                                'mobexHealthHomeProviderColor'
                                                            )
                                                        "
                                                    />
                                                    <div style="font-weight: 600; color: #000; font-size: 12px">
                                                        Main Background Color
                                                    </div>
                                                </div>
                                                <div
                                                    class="d-flex align-items-center w-25 flex-column text-center gap-2"
                                                >
                                                    <input
                                                        type="color"
                                                        name="background_color"
                                                        v-model="
                                                            record_data.mobexhealthhome_colors.provider_color
                                                                .provider_background_color
                                                        "
                                                        class="w-100"
                                                        @change="
                                                            selectCustomColorScheme(
                                                                $event,
                                                                1,
                                                                'mobexHealthHomeProviderColor'
                                                            )
                                                        "
                                                    />
                                                    <div style="font-weight: 600; color: #000; font-size: 12px">
                                                        Background Color
                                                    </div>
                                                </div>
                                                <div
                                                    class="d-flex align-items-center w-25 flex-column text-center gap-2"
                                                >
                                                    <input
                                                        type="color"
                                                        name="text_color"
                                                        v-model="
                                                            record_data.mobexhealthhome_colors.provider_color
                                                                .provider_text_color
                                                        "
                                                        class="w-100"
                                                        @change="
                                                            selectCustomColorScheme(
                                                                $event,
                                                                2,
                                                                'mobexHealthHomeProviderColor'
                                                            )
                                                        "
                                                    />
                                                    <div style="font-weight: 600; color: #000; font-size: 12px">
                                                        Text Color
                                                    </div>
                                                </div>
                                                <div
                                                    class="d-flex align-items-center w-25 flex-column text-center gap-2"
                                                >
                                                    <input
                                                        type="color"
                                                        name="header_background_color"
                                                        v-model="
                                                            record_data.mobexhealthhome_colors.provider_color
                                                                .provider_header_background_color
                                                        "
                                                        class="w-100"
                                                        @change="
                                                            selectCustomColorScheme(
                                                                $event,
                                                                3,
                                                                'mobexHealthHomeProviderColor'
                                                            )
                                                        "
                                                    />
                                                    <div style="font-weight: 600; color: #000; font-size: 12px">
                                                        Header Background Color
                                                    </div>
                                                </div>
                                                <div
                                                    class="d-flex align-items-center w-25 flex-column text-center gap-2"
                                                >
                                                    <input
                                                        type="color"
                                                        name="header_text_color"
                                                        v-model="
                                                            record_data.mobexhealthhome_colors.provider_color
                                                                .provider_header_text_color
                                                        "
                                                        class="w-100"
                                                        @change="
                                                            selectCustomColorScheme(
                                                                $event,
                                                                4,
                                                                'mobexHealthHomeProviderColor'
                                                            )
                                                        "
                                                    />
                                                    <div style="font-weight: 600; color: #000; font-size: 12px">
                                                        Header Text Color
                                                    </div>
                                                </div>
                                                <div
                                                    class="d-flex align-items-center w-25 flex-column text-center gap-2"
                                                >
                                                    <input
                                                        type="color"
                                                        name="navigation_background_color"
                                                        v-model="
                                                            record_data.mobexhealthhome_colors.provider_color
                                                                .provider_navigation_background_color
                                                        "
                                                        class="w-100"
                                                        @change="
                                                            selectCustomColorScheme(
                                                                $event,
                                                                5,
                                                                'mobexHealthHomeProviderColor'
                                                            )
                                                        "
                                                    />
                                                    <div style="font-weight: 600; color: #000; font-size: 12px">
                                                        Navigation Background Color
                                                    </div>
                                                </div>
                                                <div
                                                    class="d-flex align-items-center w-25 flex-column text-center gap-2"
                                                >
                                                    <input
                                                        type="color"
                                                        name="navigation_text_color"
                                                        v-model="
                                                            record_data.mobexhealthhome_colors.provider_color
                                                                .provider_navigation_text_color
                                                        "
                                                        class="w-100"
                                                        @change="
                                                            selectCustomColorScheme(
                                                                $event,
                                                                6,
                                                                'mobexHealthHomeProviderColor'
                                                            )
                                                        "
                                                    />
                                                    <div style="font-weight: 600; color: #000; font-size: 12px">
                                                        Navigation Text Color
                                                    </div>
                                                </div>
                                            </label>
                                        </div>
                                    </div>
                                    <div class="d-grid color-box" style="margin: 1.2rem 0rem">
                                        <div class="m-4">
                                            <div class="d-flex" style="gap: 1%">
                                                <label for="option2" style="font-weight: bold">App Color Options</label>
                                            </div>
                                            <label class="d-flex py-2" style="gap: 1%" for="customized_color">
                                                <div class="w-25">
                                                    <input
                                                        type="color"
                                                        name=""
                                                        id=""
                                                        v-model="
                                                            record_data.mobexhealthhome_colors.app_color
                                                                .background_color
                                                        "
                                                        class="w-100"
                                                        @change="
                                                            selectCustomColorScheme(
                                                                $event,
                                                                0,
                                                                'mobexHealthHomeAppColor'
                                                            )
                                                        "
                                                    />
                                                </div>
                                                <div class="w-25">
                                                    <input
                                                        type="color"
                                                        name=""
                                                        id=""
                                                        v-model="
                                                            record_data.mobexhealthhome_colors.app_color
                                                                .background_text_color
                                                        "
                                                        class="w-100"
                                                        @change="
                                                            selectCustomColorScheme(
                                                                $event,
                                                                1,
                                                                'mobexHealthHomeAppColor'
                                                            )
                                                        "
                                                    />
                                                </div>
                                                <div class="w-25">
                                                    <input
                                                        type="color"
                                                        name=""
                                                        id=""
                                                        v-model="
                                                            record_data.mobexhealthhome_colors.app_color.canvas_color
                                                        "
                                                        class="w-100"
                                                        @change="
                                                            selectCustomColorScheme(
                                                                $event,
                                                                2,
                                                                'mobexHealthHomeAppColor'
                                                            )
                                                        "
                                                    />
                                                </div>
                                                <div class="w-25">
                                                    <input
                                                        type="color"
                                                        name=""
                                                        id=""
                                                        v-model="
                                                            record_data.mobexhealthhome_colors.app_color
                                                                .canvas_text_color
                                                        "
                                                        class="w-100"
                                                        @change="
                                                            selectCustomColorScheme(
                                                                $event,
                                                                3,
                                                                'mobexHealthHomeAppColor'
                                                            )
                                                        "
                                                    />
                                                </div>
                                            </label>
                                            <label class="d-flex py-2 w-100" style="gap: 1%">
                                                <div
                                                    class="d-flex align-items-center w-25 justify-content-center"
                                                    style="font-weight: 600; color: #000; font-size: 12px"
                                                >
                                                    Background Color
                                                </div>
                                                <div
                                                    class="d-flex align-items-center w-25 justify-content-center"
                                                    style="font-weight: 600; color: #000; font-size: 12px"
                                                >
                                                    Background Text Color
                                                </div>
                                                <div
                                                    class="d-flex align-items-center w-25 justify-content-center"
                                                    style="font-weight: 600; color: #000; font-size: 12px"
                                                >
                                                    Canvas Color
                                                </div>
                                                <div
                                                    class="d-flex align-items-center w-25 justify-content-center"
                                                    style="font-weight: 600; color: #000; font-size: 12px"
                                                >
                                                    Canvas Text Color
                                                </div>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <label for="mhh">
                                    <h3 style="font-weight: 700">MHH Color Scheme*</h3>
                                </label>
                                <div class="d-flex flex-wrap justify-content-between" style="gap: 1%">
                                    <div class="d-grid color-box" style="margin: 1.2rem 0rem">
                                        <div class="m-4">
                                            <div class="d-flex" style="gap: 1%">
                                                <label for="default" style="font-weight: bold">Default Color</label>
                                                <input
                                                    type="radio"
                                                    name="color_type_mhh"
                                                    id="default"
                                                    value="default"
                                                    v-model="mhhColorOption"
                                                    style="transform: scale(0.8)"
                                                    @change="selectColorScheme($event, 'mhh')"
                                                />
                                            </div>
                                            <label class="d-flex py-2" style="gap: 1%" for="default">
                                                <div
                                                    class="w-33 d-flex align-items-center px-2"
                                                    style="
                                                        height: 30px;
                                                        background: #595bc4;
                                                        color: #fff;
                                                        font-size: 10px;
                                                    "
                                                >
                                                    #595BC4
                                                </div>
                                                <div
                                                    class="w-33 d-flex align-items-center px-2"
                                                    style="
                                                        height: 30px;
                                                        background: #ac2734;
                                                        color: #fff;
                                                        font-size: 10px;
                                                    "
                                                >
                                                    #AC2734
                                                </div>
                                                <div
                                                    class="w-33 d-flex align-items-center px-2"
                                                    style="
                                                        height: 30px;
                                                        background: #4cbc9a;
                                                        color: #fff;
                                                        font-size: 10px;
                                                    "
                                                >
                                                    #4CBC9A
                                                </div>
                                            </label>
                                        </div>
                                    </div>
                                    <div class="d-grid color-box" style="margin: 1.2rem 0rem">
                                        <div class="m-4">
                                            <div class="d-flex" style="gap: 1%">
                                                <label for="option1" style="font-weight: bold">Option 1</label>
                                                <input
                                                    type="radio"
                                                    name="color_type_mhh"
                                                    id="option1"
                                                    value="option1"
                                                    v-model="mhhColorOption"
                                                    style="transform: scale(0.8)"
                                                    @change="selectColorScheme($event, 'mhh')"
                                                />
                                            </div>
                                            <label class="d-flex py-2" style="gap: 1%" for="option1">
                                                <div
                                                    class="w-33 d-flex align-items-center px-2"
                                                    style="height: 30px; background: #000; color: #fff; font-size: 10px"
                                                >
                                                    #000
                                                </div>
                                                <div
                                                    class="w-33 d-flex align-items-center px-2"
                                                    style="
                                                        height: 30px;
                                                        background: #3858e9;
                                                        color: #fff;
                                                        font-size: 10px;
                                                    "
                                                >
                                                    #3858E9
                                                </div>
                                                <div
                                                    class="w-33 d-flex align-items-center px-2"
                                                    style="
                                                        height: 30px;
                                                        background: #15ae80;
                                                        color: #fff;
                                                        font-size: 10px;
                                                    "
                                                >
                                                    #15AE80
                                                </div>
                                            </label>
                                        </div>
                                    </div>
                                    <div class="d-grid color-box" style="margin: 1.2rem 0rem">
                                        <div class="m-4">
                                            <div class="d-flex" style="gap: 1%">
                                                <label for="option2" style="font-weight: bold">Option 2</label>
                                                <input
                                                    type="radio"
                                                    name="color_type_mhh"
                                                    id="option2"
                                                    value="option2"
                                                    v-model="mhhColorOption"
                                                    style="transform: scale(0.8)"
                                                    @change="selectColorScheme($event, 'mhh')"
                                                />
                                            </div>
                                            <label class="d-flex py-2" style="gap: 1%" for="option2">
                                                <div
                                                    class="w-33 d-flex align-items-center px-2"
                                                    style="
                                                        height: 30px;
                                                        background: #2271b1;
                                                        color: #fff;
                                                        font-size: 10px;
                                                    "
                                                >
                                                    #2271B1
                                                </div>
                                                <div
                                                    class="w-33 d-flex align-items-center px-2"
                                                    style="
                                                        height: 30px;
                                                        background: #9ea476;
                                                        color: #fff;
                                                        font-size: 10px;
                                                    "
                                                >
                                                    #9EA476
                                                </div>
                                                <div
                                                    class="w-33 d-flex align-items-center px-2"
                                                    style="
                                                        height: 30px;
                                                        background: #dd823b;
                                                        color: #fff;
                                                        font-size: 10px;
                                                    "
                                                >
                                                    #DD823B
                                                </div>
                                            </label>
                                        </div>
                                    </div>
                                    <div class="d-grid color-box" style="margin: 1.2rem 0rem">
                                        <div class="m-4">
                                            <div class="d-flex" style="gap: 1%">
                                                <label for="customized_color" style="font-weight: bold"
                                                    >Customize Color Option</label
                                                >
                                                <input
                                                    type="radio"
                                                    name="color_type_mhh"
                                                    id="customized_color"
                                                    value="customized_color"
                                                    v-model="mhhColorOption"
                                                    style="transform: scale(0.8)"
                                                    @change="selectColorScheme($event, 'mhh')"
                                                />
                                            </div>
                                            <label
                                                class="d-flex py-2"
                                                style="gap: 1%; flex-direction: column"
                                                for="customized_color"
                                            >
                                                <div
                                                    class="d-flex align-items-center py-1"
                                                    style="height: 1px; font-size: 11px"
                                                >
                                                    Button Color
                                                </div>
                                                <div class="d-flex py-2" style="gap: 1%">
                                                    <div class="w-33">
                                                        <input
                                                            type="color"
                                                            name=""
                                                            id=""
                                                            value="#efefef"
                                                            class="w-100"
                                                            v-if="mhhColorOption == 'customized_color'"
                                                            v-model="record_data.mhh_colors.colors[0][0]"
                                                            @change="selectCustomColorScheme($event, 1, 'mhh')"
                                                        />
                                                        <input
                                                            type="color"
                                                            name=""
                                                            id=""
                                                            :value="defaultColor"
                                                            class="w-100"
                                                            disabled
                                                            v-else
                                                        />
                                                    </div>
                                                    <div class="w-33">
                                                        <input
                                                            type="color"
                                                            name=""
                                                            id=""
                                                            value="#efefef"
                                                            class="w-100"
                                                            v-if="mhhColorOption == 'customized_color'"
                                                            v-model="record_data.mhh_colors.colors[1][0]"
                                                            @change="selectCustomColorScheme($event, 2, 'mhh')"
                                                        />
                                                        <input
                                                            type="color"
                                                            name=""
                                                            id=""
                                                            :value="defaultColor"
                                                            class="w-100"
                                                            disabled
                                                            v-else
                                                        />
                                                    </div>
                                                    <div class="w-33">
                                                        <input
                                                            type="color"
                                                            name=""
                                                            id=""
                                                            value="#efefef"
                                                            class="w-100"
                                                            v-if="mhhColorOption == 'customized_color'"
                                                            v-model="record_data.mhh_colors.colors[2][0]"
                                                            @change="selectCustomColorScheme($event, 3, 'mhh')"
                                                        />
                                                        <input
                                                            type="color"
                                                            name=""
                                                            id=""
                                                            :value="defaultColor"
                                                            class="w-100"
                                                            disabled
                                                            v-else
                                                        />
                                                    </div>
                                                </div>
                                                <div
                                                    class="d-flex align-items-center py-1"
                                                    style="height: 1px; font-size: 11px"
                                                >
                                                    Text Color
                                                </div>
                                                <div class="d-flex py-2" style="gap: 1%">
                                                    <div class="w-33">
                                                        <input
                                                            type="color"
                                                            name=""
                                                            id=""
                                                            value="#efefef"
                                                            class="w-100"
                                                            v-if="mhhColorOption == 'customized_color'"
                                                            v-model="record_data.mhh_colors.colors[0][1]"
                                                            @change="selectCustomColorScheme($event, 4, 'mhh')"
                                                        />
                                                        <input
                                                            type="color"
                                                            name=""
                                                            id=""
                                                            :value="defaultColor"
                                                            class="w-100"
                                                            disabled
                                                            v-else
                                                        />
                                                    </div>
                                                    <div class="w-33">
                                                        <input
                                                            type="color"
                                                            name=""
                                                            id=""
                                                            value="#efefef"
                                                            class="w-100"
                                                            v-if="mhhColorOption == 'customized_color'"
                                                            v-model="record_data.mhh_colors.colors[1][1]"
                                                            @change="selectCustomColorScheme($event, 5, 'mhh')"
                                                        />
                                                        <input
                                                            type="color"
                                                            name=""
                                                            id=""
                                                            :value="defaultColor"
                                                            class="w-100"
                                                            disabled
                                                            v-else
                                                        />
                                                    </div>
                                                    <div class="w-33">
                                                        <input
                                                            type="color"
                                                            name=""
                                                            id=""
                                                            value="#efefef"
                                                            class="w-100"
                                                            v-if="mhhColorOption == 'customized_color'"
                                                            v-model="record_data.mhh_colors.colors[2][1]"
                                                            @change="selectCustomColorScheme($event, 6, 'mhh')"
                                                        />
                                                        <input
                                                            type="color"
                                                            name=""
                                                            id=""
                                                            :value="defaultColor"
                                                            class="w-100"
                                                            disabled
                                                            v-else
                                                        />
                                                    </div>
                                                </div>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                class="d-flex p-3 color-box gap-2"
                                style="width: 100% !important; box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.12)"
                            >
                                <div class="w-50 d-flex align-items-center">
                                    <div class="my-3" style="width: 38vw">
                                        <label
                                            for="tags"
                                            class="d-flex justify-content-between align-items-center upload-section"
                                        >
                                            <span style="font-size: 16px; font-weight: 600; margin: 0 0 0 5%"
                                                >Create company specific Project Tag</span
                                            >
                                            <span
                                                class="upload-btn px-5 py-3 d-flex gap-2 align-items-center"
                                                style="font-size: 14px"
                                                @click="showTagModal = true"
                                            >
                                                <img src="@/assets/px/add.png" alt="" style="height: 25px" />
                                                Create</span
                                            >
                                        </label>
                                    </div>
                                </div>
                                <div class="w-50">
                                    <div class="d-flex flex-wrap gap-2">
                                        <span
                                            v-for="(tag, index) in record_data.tags"
                                            :key="index"
                                            class="d-flex align-items-center gap-3 cursor-pointer py-2 px-4"
                                            style="
                                                background: #ebeff4;
                                                border-radius: 20px;
                                                font-size: 15px !important;
                                                width: fit-content;
                                                text-transform: capitalize;
                                            "
                                        >
                                            <ejs-tooltip
                                                class="tooltipcontainer"
                                                :content="tag.description ? tag.description : 'No Description Added'"
                                                target="#tag"
                                                cssClass="customtooltip"
                                                ><span
                                                    id="tag"
                                                    style="font-size: 15px !important"
                                                    @click="openTagModal(tag, index)"
                                                    >{{ tag.name }}</span
                                                >
                                            </ejs-tooltip>
                                            <ejs-tooltip
                                                class="tooltipcontainer"
                                                content="Remove"
                                                target="#remove"
                                                cssClass="customtooltip"
                                            >
                                                <img
                                                    id="remove"
                                                    src="@/assets/px/cancel.svg"
                                                    alt=""
                                                    style="height: 20px"
                                                    @click="removetag(index)"
                                                />
                                            </ejs-tooltip>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="d-flex flex-wrap p-3" style="gap: 0.5em">
                                <div class="w-100">
                                    <label for="default">
                                        <h3 style="font-weight: 700">Select and Upload Logo*</h3>
                                    </label>
                                </div>
                                <div class="d-flex w-100">
                                    <div class="w-50">
                                        <div class="my-3" style="width: 38vw">
                                            <input
                                                type="file"
                                                name="company_logo"
                                                id="company_logo"
                                                placeholder="Upload Company logo"
                                                hidden
                                                @change="uploadImage($event)"
                                                accept="image/,.jpg, .webp, .png"
                                                :disabled="logoLoading"
                                            />
                                            <label
                                                for="company_logo"
                                                class="d-flex justify-content-between align-items-center upload-section"
                                            >
                                                <span style="font-size: 14px; margin: 0 0 0 5%"
                                                    >Upload Company logo</span
                                                >
                                                <span
                                                    v-if="logoLoading"
                                                    class="upload-btn py-3 px-3 d-flex"
                                                    style="gap: 10px"
                                                >
                                                    <SpinLoader />Uploading
                                                </span>
                                                <span v-else class="upload-btn px-5 py-3">Upload</span>
                                            </label>
                                        </div>
                                        <div class="scroll-Wrapper">
                                            <div class="scroll-container" style="gap: 1%">
                                                <div
                                                    v-for="logo in logoImages"
                                                    :key="logo.id"
                                                    style="position: relative; text-align: -webkit-center"
                                                >
                                                    <img
                                                        :src="logo.image"
                                                        :class="logo.id == companyLogo.id ? 'active-bg' : ''"
                                                        style="
                                                            height: 100px;
                                                            max-width: 100px;
                                                            border-radius: 20px;
                                                            cursor: pointer;
                                                        "
                                                        @click="selectCompanyLogo(logo)"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <span v-if="logoImageErr" style="color: red">{{ logoImageErr }}</span>
                                    </div>
                                    <div class="w-50">
                                        <h6 style="font-weight: 700">Scenarios for Image Upload</h6>
                                        <div class="grid">
                                            <!-- <div class="d-flex align-items-center">
                                                <span class="material-symbols-outlined icon scenario-item">
                                                    check_small
                                                </span>
                                                <span class="scenario-text"
                                                    >You can choose only one file at a time.</span
                                                >
                                            </div> -->
                                            <div class="d-flex align-items-center">
                                                <span class="material-symbols-outlined icon scenario-item">
                                                    check_small
                                                </span>
                                                <span class="scenario-text"
                                                    >Image extensions should JPG, WEBP and PNG.</span
                                                >
                                            </div>
                                            <div class="d-flex align-items-center">
                                                <span class="material-symbols-outlined icon scenario-item">
                                                    check_small
                                                </span>
                                                <span class="scenario-text"
                                                    >Resolution should be not less than 1920/1080px</span
                                                >
                                            </div>
                                            <div class="d-flex align-items-center">
                                                <span class="material-symbols-outlined icon scenario-item">
                                                    check_small
                                                </span>
                                                <span class="scenario-text"
                                                    >Image size should be no more than 10MB.</span
                                                >
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="d-flex flex-wrap p-3" style="gap: 0.5em">
                                <div class="w-100">
                                    <label for="uploadWA">
                                        <h3 style="font-weight: 700">Upload Waiting Animation*</h3>
                                    </label>
                                </div>
                                <div class="d-flex w-100">
                                    <div class="w-50">
                                        <div class="my-3" style="width: 38vw">
                                            <input
                                                type="file"
                                                name="waiting_animation"
                                                id="waiting_animation"
                                                placeholder="Upload Waiting Animation"
                                                hidden
                                                @change="uploadImage($event)"
                                                accept=".gif"
                                                :disabled="waitingAnimationLoading"
                                            />
                                            <label
                                                for="waiting_animation"
                                                class="d-flex justify-content-between align-items-center upload-section"
                                            >
                                                <span style="font-size: 14px; margin: 0 0 0 5%"
                                                    >Upload waiting animation</span
                                                >
                                                <span
                                                    v-if="waitingAnimationLoading"
                                                    class="upload-btn py-3 px-3 d-flex"
                                                    style="gap: 10px"
                                                >
                                                    <SpinLoader />Uploading
                                                </span>
                                                <span v-else class="upload-btn px-5 py-3">Upload</span>
                                            </label>
                                        </div>
                                        <div class="scroll-Wrapper">
                                            <div class="scroll-container" style="gap: 1%">
                                                <div
                                                    v-for="gif in waitingAnimations"
                                                    :key="gif.id"
                                                    style="position: relative; text-align: -webkit-center"
                                                >
                                                    <img
                                                        :src="gif.image"
                                                        style="
                                                            height: 100px;
                                                            max-width: 100px;
                                                            border-radius: 20px;
                                                            cursor: pointer;
                                                        "
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <span v-if="waitingAnimationErr" style="color: red">{{
                                            waitingAnimationErr
                                        }}</span>
                                    </div>
                                    <div class="w-50">
                                        <h6 style="font-weight: 700">Scenarios for Image Upload</h6>
                                        <div class="grid">
                                            <div class="d-flex align-items-center">
                                                <span class="material-symbols-outlined icon scenario-item">
                                                    check_small
                                                </span>
                                                <span class="scenario-text"
                                                    >You can choose only one file at a time.</span
                                                >
                                            </div>
                                            <div class="d-flex align-items-center">
                                                <span class="material-symbols-outlined icon scenario-item">
                                                    check_small
                                                </span>
                                                <span class="scenario-text">Image extensions should GIF.</span>
                                            </div>
                                            <div class="d-flex align-items-center">
                                                <span class="material-symbols-outlined icon scenario-item">
                                                    check_small
                                                </span>
                                                <span class="scenario-text"
                                                    >Resolution should be not less than 1920/1080px</span
                                                >
                                            </div>
                                            <div class="d-flex align-items-center">
                                                <span class="material-symbols-outlined icon scenario-item">
                                                    check_small
                                                </span>
                                                <span class="scenario-text"
                                                    >Image size should be no more than 10MB.</span
                                                >
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="d-flex flex-wrap p-3" style="gap: 0.5em">
                                <div class="w-100">
                                    <label>
                                        <h3 style="font-weight: 700">Upload Logo (For QR code use only)</h3>
                                    </label>
                                </div>
                                <div class="d-flex w-100">
                                    <div class="w-50">
                                        <div class="my-3" style="width: 38vw">
                                            <input
                                                type="file"
                                                name="company_qr_logo"
                                                id="company_qr_logo"
                                                placeholder="Upload Company logo for QR"
                                                hidden
                                                @change="uploadImage($event)"
                                                accept="image/,.jpg, .webp, .png"
                                                :disabled="qrLogoLoading"
                                            />
                                            <label
                                                for="company_qr_logo"
                                                class="d-flex justify-content-between align-items-center upload-section"
                                            >
                                                <span style="font-size: 14px; margin: 0 0 0 5%"
                                                    >Upload Company logo for QR</span
                                                >
                                                <span
                                                    v-if="qrLogoLoading"
                                                    class="upload-btn py-3 px-3 d-flex"
                                                    style="gap: 10px"
                                                >
                                                    <SpinLoader />Uploading
                                                </span>
                                                <span v-else class="upload-btn px-5 py-3">Upload</span>
                                            </label>
                                        </div>
                                        <div class="scroll-Wrapper">
                                            <div class="scroll-container" style="gap: 1%">
                                                <div
                                                    v-for="logo in qrLogoImages"
                                                    :key="logo.id"
                                                    style="position: relative; text-align: -webkit-center"
                                                >
                                                    <img
                                                        :src="logo.image"
                                                        style="
                                                            height: 100px;
                                                            max-width: 100px;
                                                            border-radius: 20px;
                                                            cursor: pointer;
                                                        "
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <span v-if="qrLogoImageErr" style="color: red">{{ qrLogoImageErr }}</span>
                                    </div>
                                    <div class="w-50">
                                        <h6 style="font-weight: 700">Scenarios for Image Upload</h6>
                                        <div class="grid">
                                            <div class="d-flex align-items-center">
                                                <span class="material-symbols-outlined icon scenario-item">
                                                    check_small
                                                </span>
                                                <span class="scenario-text"
                                                    >You can choose only one file at a time.</span
                                                >
                                            </div>
                                            <div class="d-flex align-items-center">
                                                <span class="material-symbols-outlined icon scenario-item">
                                                    check_small
                                                </span>
                                                <span class="scenario-text"
                                                    >Image extensions should JPG, WEBP and PNG.</span
                                                >
                                            </div>
                                            <div class="d-flex align-items-center">
                                                <span class="material-symbols-outlined icon scenario-item">
                                                    check_small
                                                </span>
                                                <span class="scenario-text"
                                                    >Resolution should be not less than 1920/1080px</span
                                                >
                                            </div>
                                            <div class="d-flex align-items-center">
                                                <span class="material-symbols-outlined icon scenario-item">
                                                    check_small
                                                </span>
                                                <span class="scenario-text"
                                                    >Image size should be no more than 10MB.</span
                                                >
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="d-flex flex-wrap p-3" style="gap: 0.5em">
                                <div class="w-100">
                                    <label for="default">
                                        <h3 style="font-weight: 700">Upload Background Images</h3>
                                    </label>
                                </div>
                                <div class="d-flex w-100">
                                    <div class="w-50">
                                        <div class="my-3" style="width: 38vw">
                                            <input
                                                type="file"
                                                name="company_background_image"
                                                id="company_background_image"
                                                placeholder="Upload Company background"
                                                hidden
                                                @change="uploadImage($event)"
                                                accept="image/,.jpg, .webp, .png"
                                                :disabled="backgroundLoading"
                                            />
                                            <label
                                                for="company_background_image"
                                                class="d-flex justify-content-between align-items-center upload-section"
                                            >
                                                <span style="font-size: 14px; margin: 0 0 0 5%">Upload Background</span>
                                                <span
                                                    v-if="backgroundLoading"
                                                    class="upload-btn py-3 px-3 d-flex"
                                                    style="gap: 10px"
                                                >
                                                    <SpinLoader />Uploading
                                                </span>
                                                <span v-else class="upload-btn px-5 py-3">Upload</span>
                                            </label>
                                        </div>
                                        <div class="scroll-Wrapper">
                                            <div class="scroll-container" style="gap: 1%">
                                                <div
                                                    v-for="bg in backgroundImages"
                                                    :key="bg.id"
                                                    style="position: relative; text-align: -webkit-center"
                                                >
                                                    <img
                                                        :src="bg.image"
                                                        style="
                                                            height: 100px;
                                                            max-width: 100px;
                                                            border-radius: 20px;
                                                            cursor: pointer;
                                                        "
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <span v-if="backgroundImageErr" style="color: red">{{
                                            backgroundImageErr
                                        }}</span>
                                    </div>
                                    <div class="w-50">
                                        <h6 style="font-weight: 700">Scenarios for Image Upload</h6>
                                        <div class="grid">
                                            <!-- <div class="d-flex align-items-center">
                                                <span class="material-symbols-outlined icon scenario-item">
                                                    check_small
                                                </span>
                                                <span class="scenario-text"
                                                    >You can choose only one file at a time.</span
                                                >
                                            </div> -->
                                            <div class="d-flex align-items-center">
                                                <span class="material-symbols-outlined icon scenario-item">
                                                    check_small
                                                </span>
                                                <span class="scenario-text"
                                                    >Image extensions should JPG, WEBP and PNG.</span
                                                >
                                            </div>
                                            <div class="d-flex align-items-center">
                                                <span class="material-symbols-outlined icon scenario-item">
                                                    check_small
                                                </span>
                                                <span class="scenario-text"
                                                    >Resolution should be not less than 1920/1080px</span
                                                >
                                            </div>
                                            <div class="d-flex align-items-center">
                                                <span class="material-symbols-outlined icon scenario-item">
                                                    check_small
                                                </span>
                                                <span class="scenario-text"
                                                    >Image size should be no more than 10MB.</span
                                                >
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="d-flex flex-wrap p-3" style="gap: 0.5em">
                                <div class="w-100">
                                    <label for="default">
                                        <h3 style="font-weight: 700">Upload Screen Saver Images</h3>
                                    </label>
                                </div>
                                <div class="d-flex w-100">
                                    <div class="w-50">
                                        <div class="my-3" style="width: 38vw">
                                            <input
                                                type="file"
                                                name="company_digital_signage_image"
                                                id="company_digital_signage_image"
                                                placeholder="Upload Company Screen Saver"
                                                hidden
                                                @change="uploadImage($event)"
                                                accept="image/,.jpg, .webp, .png"
                                                :disabled="screenSaverLoading"
                                            />
                                            <label
                                                for="company_digital_signage_image"
                                                class="d-flex justify-content-between align-items-center upload-section"
                                            >
                                                <span style="font-size: 14px; margin: 0 0 0 5%"
                                                    >Upload Screen Saver</span
                                                >
                                                <span
                                                    v-if="screenSaverLoading"
                                                    class="upload-btn py-3 px-3 d-flex"
                                                    style="gap: 10px"
                                                >
                                                    <SpinLoader />Uploading
                                                </span>
                                                <span v-else class="upload-btn px-5 py-3">Upload</span>
                                            </label>
                                        </div>
                                        <div class="scroll-Wrapper">
                                            <div class="scroll-container" style="gap: 1%">
                                                <div
                                                    v-for="bg in screenSaverImages"
                                                    :key="bg.id"
                                                    style="position: relative; text-align: -webkit-center"
                                                >
                                                    <img
                                                        :src="bg.image"
                                                        style="
                                                            height: 100px;
                                                            max-width: 100px;
                                                            border-radius: 20px;
                                                            cursor: pointer;
                                                        "
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <span v-if="backgroundImageErr" style="color: red">{{
                                            backgroundImageErr
                                        }}</span>
                                    </div>
                                    <div class="w-50">
                                        <h6 style="font-weight: 700">Scenarios for Image Upload</h6>
                                        <div class="grid">
                                            <!-- <div class="d-flex align-items-center">
                                                <span class="material-symbols-outlined icon scenario-item">
                                                    check_small
                                                </span>
                                                <span class="scenario-text"
                                                    >You can choose only one file at a time.</span
                                                >
                                            </div> -->
                                            <div class="d-flex align-items-center">
                                                <span class="material-symbols-outlined icon scenario-item">
                                                    check_small
                                                </span>
                                                <span class="scenario-text"
                                                    >Image extensions should JPG, WEBP and PNG.</span
                                                >
                                            </div>
                                            <div class="d-flex align-items-center">
                                                <span class="material-symbols-outlined icon scenario-item">
                                                    check_small
                                                </span>
                                                <span class="scenario-text"
                                                    >Resolution should be not less than 1920/1080px</span
                                                >
                                            </div>
                                            <div class="d-flex align-items-center">
                                                <span class="material-symbols-outlined icon scenario-item">
                                                    check_small
                                                </span>
                                                <span class="scenario-text"
                                                    >Image size should be no more than 10MB.</span
                                                >
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="d-flex justify-content-end mt-5">
                            <button type="button" v-if="isNext" class="other-btn" @click="isNext = false">
                                Go Back
                            </button>
                            <button type="button" class="save-btn" @click="isNext ? createRecord() : onNext()">
                                {{ isNext ? 'Add Company Details' : 'Next' }}
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </ValidationObserver>
        <TagModal
            v-if="showTagModal"
            :tagList="record_data.tags"
            :tagInfo="tagInfo"
            :tagIndex="tagIndex"
            @save="saveTag"
            @cancel="closeTagModal"
        />
        <Loading v-if="loading" />
    </div>
</template>
<script>
    import { ValidationObserver } from 'vee-validate';
    import { companies, dashboardBuilder, cardColors } from '../../util/apiRequests';
    import CompanyLogoUpload from '../../components/company/company-information/CompanyLogoUpload.vue';
    import TagModal from '../../pages/company/TagModal.vue';
    import SpinLoader from '@/components/general/loading/SpinLoader.vue';
    import Loading from '@/components/general/loading/loading.vue';

    const initialFormFields = {
        company_name: '',
        company_email: '',
        company_website: '',
        address: '',
        city: '',
        state: '',
        zip: '',
        file: '',
        phone: '',
        logo: '',
        mobexhealthhome_colors: {
            provider_color: {
                provider_main_background_color: '#FF6600',
                provider_background_color: '#ffffff',
                provider_text_color: '#000000',
                provider_header_background_color: '#329DE6',
                provider_header_text_color: '#ffffff',
                provider_navigation_background_color: '#000000',
                provider_navigation_text_color: '#ffffff',
            },
            app_color: {
                background_color: '#F1f7ff',
                background_text_color: '#000000',
                canvas_color: '#1773b1',
                canvas_text_color: '#ffffff',
            },
        },
        kiosk_colors: {
            selectedOption: 'primary_colors',
            primary_colors: ['#FFFFFF', '#FF6600', '#FFFFFF', '#000000'],
            secondary_colors: ['#FF6600', '#FFFFFF', '#FF6600', '#FFFFFF'],
            customized_colors: ['#FFFFFF', '#FF6600', '#FFFFFF', '#000000'],
            colors: ['#FFFFFF', '#000000'],
        },
        mhh_colors: {
            selectedOption: 'default',
            colors: [
                ['#595BC4', '#FFFFFF'],
                ['#AC2734', '#FFFFFF'],
                ['#4CBC9A', '#FFFFFF'],
            ],
            buttoncolor: '#595BC4',
            textcolor: '#FFFFFF',
        },
        sensitiveScreenTimeout: 30,
        sensitiveAlertTimeout: 15,
        tags: [],
    };

    const sensitiveScreenTimeoutList = [
        {
            id: 30,
            value: '30 seconds',
        },
        {
            id: 60,
            value: '1 minute',
        },
        {
            id: 120,
            value: '2 minutes',
        },
        {
            id: 180,
            value: '3 minutes',
        },
        {
            id: 240,
            value: '4 minutes',
        },
        {
            id: 300,
            value: '5 minutes',
        },
    ];

    const sensitiveAlertTimeoutList = [
        {
            id: 15,
            value: '15 seconds',
        },
        {
            id: 30,
            value: '30 seconds',
        },
        {
            id: 60,
            value: '1 minute',
        },
    ];

    export default {
        components: { ValidationObserver, CompanyLogoUpload, TagModal, Loading, SpinLoader },
        name: 'AddNewCompany',
        props: {
            record_id: [String, Number],
        },
        data() {
            return {
                defaultColor: '#efefef',
                kioskColorOption: 'default',
                mhhColorOption: 'default',
                isNext: false,
                record_data: { ...initialFormFields },
                backgroundImages: [],
                backgroundImageErr: null,
                logoImages: [],
                logoImageErr: null,
                screenSaverImages: [],
                screenSaverImageErr: null,
                waitingAnimations: [],
                waitingAnimationLoading: false,
                waitingAnimationErr: null,
                loading: true,
                image: '',
                logoLoading: false,
                backgroundLoading: false,
                screenSaverLoading: false,
                companyLogo: {},
                sensitiveScreenTimeout: { ...sensitiveScreenTimeoutList[0] },
                sensitiveAlertTimeout: { ...sensitiveAlertTimeoutList[0] },
                sensitiveScreenTimeoutList,
                sensitiveAlertTimeoutList,
                showTagModal: false,
                qrLogoImages: [],
                qrLogoImageErr: null,
                qrLogoLoading: false,
                tagInfo: null,
                tagIndex: null,
            };
        },
        methods: {
            async onNext() {
                window.scrollTo(0, 0);

                const form = this.$refs.form;
                const isFormValid = await form.validate();
                if (
                    !isFormValid ||
                    this.record_data.sensitiveScreenTimeout == '' ||
                    this.record_data.sensitiveAlertTimeout == ''
                ) {
                    this.$toasted.error('Looks like filled invalid data in some fields.');
                    return;
                }
                if (this.record_data.sensitiveScreenTimeout <= this.record_data.sensitiveAlertTimeout) {
                    this.$toasted.error('Sensitive Inactivity timeout must be less than sensitive screen timeout');
                    return;
                }
                this.isNext = true;
            },
            async createRecord() {
                const form = this.$refs.form;
                const isFormValid = await form.validate();
                if (!isFormValid) {
                    this.$toasted.error('Looks like filled invalid data in some fields.');
                    return;
                }

                if (!this.companyLogo.fileId) {
                    this.$toasted.error('Please select or upload company logo');
                    return;
                }

                if (
                    !this.logoImages.length ||
                    !this.waitingAnimations.length ||
                    !this.backgroundImages.length ||
                    !this.screenSaverImages.length
                ) {
                    this.$toasted.error(
                        'Please upload at least one image in each waiting animation/ background image/ screen saver image'
                    );
                    return;
                }

                let res; // declare `res` here so it's available throughout the function
                try {
                    this.loading = true;

                    this.record_data.file = this.companyLogo.fileId;
                    this.record_data.logo = this.companyLogo.fileId.split('/')[1]?.split('.')[0];
                    this.record_data.kiosk_colors = JSON.stringify(this.record_data.kiosk_colors);
                    this.record_data.mhh_colors = JSON.stringify(this.record_data.mhh_colors);
                    let mobexhealthhome_colors = {
                        ...this.record_data.mobexhealthhome_colors.provider_color,
                        ...this.record_data.mobexhealthhome_colors.app_color
                    };
                    delete this.record_data.mobexhealthhome_colors;

                    const endpoint = companies.createCompany();
                    res = await this.$api.post(endpoint, this.record_data);

                    if (res.status === 201) {
                        const companyId = res?.data?.data?.id;

                        await this.createCardColors(companyId, mobexhealthhome_colors);
                        await this.changeActiveStatus(res?.data?.data?.id);
                        
                        this.resetForm();
                        this.$toasted.success('Successfully created Company');
                        await this.$router.push({ name: 'CompanyList', replace: true });
                    } else {
                        this.$toasted.error(res?.data?.error);
                    }
                } catch (err) {
                    this.$toasted.error('Failed to create Record. Please try again later.');
                } finally {
                    this.loading = false;
                }
            },
            async createCardColors(companyId, mobexhealthhome_colors) {
                try {
                    const createColorEndpoint = cardColors.createCardColor(companyId);
                    await this.$api.post(createColorEndpoint, mobexhealthhome_colors)
                } catch (err) {
                    this.$toasted.error('Failed to create Card Colors. Please try again later.');
                }
            },
            resetForm() {
                this.record_data = { ...initialFormFields };
                this.image = '';
                requestAnimationFrame(() => {
                    this.$refs.form.reset();
                });
            },
            selectCompanyLogo(logo) {
                this.companyLogo = {
                    id: logo.id,
                    image: logo.image,
                    fileId: logo.file_id,
                };
                this.image = logo.image;
            },
            async validateForm() {
                const isValid = 1; // await this.$refs.form.validate()

                if (!isValid) {
                    this.$toasted.error('Please fill out all required fields');
                }

                return isValid;
            },
            async assignId(response) {
                this.record_data.logo = response.fileId;
            },
            back() {
                this.$router.back();
            },
            async selectFile() {
                this.$refs.upload.click();
            },
            async selectColorScheme(event, type) {
                if (type === 'kiosk') {
                    switch (event.target.value) {
                        case 'option1':
                            this.record_data.kiosk_colors = {
                                selectedOption: 'option1',
                                colors: ['#5155C3', '#FFFFFF'],
                                buttoncolor: '#5155C3',
                                textcolor: '#FFFFFF',
                            };
                            break;
                        case 'option2':
                            this.record_data.kiosk_colors = {
                                selectedOption: 'option2',
                                colors: ['#4CBC9A', '#FFFFFF'],
                                buttoncolor: '#4CBC9A',
                                textcolor: '#FFFFFF',
                            };
                            break;
                        case 'customized_color':
                            this.record_data.kiosk_colors = {
                                selectedOption: 'customized_color',
                                colors: ['#efefef', '#efefef'],
                                buttoncolor: '#efefef',
                                textcolor: '#efefef',
                            };
                            break;

                        default:
                            this.record_data.kiosk_colors = {
                                selectedOption: 'default',
                                colors: ['#FFFFFF', '#5155C3'],
                                buttoncolor: '#FFFFFF',
                                textcolor: '#5155C3',
                            };
                    }
                } else {
                    switch (event.target.value) {
                        case 'option1':
                            this.record_data.mhh_colors = {
                                selectedOption: 'option1',
                                colors: [
                                    ['#000', '#FFFFFF'],
                                    ['#3858E9', '#FFFFFF'],
                                    ['#15AE80', '#FFFFFF'],
                                ],
                                buttoncolor: '#000',
                                textcolor: '#FFFFFF',
                            };
                            break;
                        case 'option2':
                            this.record_data.mhh_colors = {
                                selectedOption: 'option2',
                                colors: [
                                    ['#2271B1', '#FFFFFF'],
                                    ['#9EA476', '#FFFFFF'],
                                    ['#DD823B', '#FFFFFF'],
                                ],
                                buttoncolor: '#2271B1',
                                textcolor: '#FFFFFF',
                            };
                            break;
                        case 'customized_color':
                            this.record_data.mhh_colors = {
                                selectedOption: 'customized_color',
                                colors: [
                                    ['#efefef', '#efefef'],
                                    ['#efefef', '#efefef'],
                                    ['#efefef', '#efefef'],
                                ],
                                buttoncolor: '#efefef',
                                textcolor: '#efefef',
                            };
                            break;

                        default:
                            this.record_data.mhh_colors = {
                                selectedOption: 'default',
                                colors: [
                                    ['#595BC4', '#FFFFFF'],
                                    ['#AC2734', '#FFFFFF'],
                                    ['#4CBC9A', '#FFFFFF'],
                                ],
                                buttoncolor: '#595BC4',
                                textcolor: '#FFFFFF',
                            };
                    }
                }
            },
            async selectCustomColorScheme(event, colorNo, type) {
                let color = event.target.value;
                if (type == 'mobexHealthHomeProviderColor') {
                    const colorSequence = [
                        'main_background_color',
                        'background_color',
                        'text_color',
                        'header_background_color',
                        'header_text_color',
                        'navigation_background_color',
                        'navigation_text_color',
                    ];

                    this.record_data.mobexhealthhome_colors.provider_color[colorSequence[colorNo]] = color;
                } else if (type == 'mobexHealthHomeAppColor') {
                    switch (colorNo) {
                        case 0:
                            this.record_data.mobexhealthhome_colors.app_color.background_color = color;
                            break;
                        case 1:
                            this.record_data.mobexhealthhome_colors.app_color.background_text_color = color;
                            break;
                        case 2:
                            this.record_data.mobexhealthhome_colors.app_color.canvas_color = color;
                            break;
                        case 3:
                            this.record_data.mobexhealthhome_colors.app_color.canvas_text_color = color;
                            break;
                    }
                } else {
                    switch (colorNo) {
                        case 1:
                            this.record_data.mhh_colors.colors[0][0] = color;
                            this.record_data.mhh_colors.buttoncolor = color;
                            break;
                        case 2:
                            this.record_data.mhh_colors.colors[1][0] = color;
                            break;
                        case 3:
                            this.record_data.mhh_colors.colors[2][0] = color;
                            break;
                        case 4:
                            this.record_data.mhh_colors.colors[0][1] = color;
                            this.record_data.mhh_colors.textcolor = color;
                            break;
                        case 5:
                            this.record_data.mhh_colors.colors[1][1] = color;
                            break;
                        case 6:
                            this.record_data.mhh_colors.colors[2][1] = color;
                            break;
                    }
                }
            },
            async uploadImage(event) {
                const type = event.target.id;
                let file = event.target.files[0];
                if (file.size / 1024 / 1024 > 10) {
                    if (type === 'company_logo') {
                        this.logoImageErr = 'Size should not be greater than 10MB';
                    } else if (type === 'company_digital_signage_image') {
                        this.screenSaverImageErr = 'Size should not be greater than 10MB';
                    } else if (type === 'waiting_animation_image') {
                        this.waitingAnimationErr = 'Size should not be greater than 10MB';
                    } else if (type === 'company_qr_logo') {
                        this.qrLogoImageErr = 'Size should not be greater than 10MB';
                    } else {
                        this.backgroundImageErr = 'Size should not be greater than 10MB';
                    }
                    return;
                } else {
                    if (type === 'company_logo') {
                        this.logoImageErr = null;
                        this.logoLoading = true;
                    } else if (type === 'company_digital_signage_image') {
                        this.screenSaverImageErr = null;
                        this.screenSaverLoading = true;
                    } else if (type === 'waiting_animation') {
                        this.waitingAnimationErr = null;
                        this.waitingAnimationLoading = true;
                    } else if (type === 'company_qr_logo') {
                        this.qrLogoImageErr = null;
                        this.qrLogoLoading = true;
                    } else {
                        this.backgroundImageErr = null;
                        this.backgroundLoading = true;
                    }
                }
                var reader = new FileReader();
                reader.readAsDataURL(file);
                let that = this;
                reader.onload = async function () {
                    const formData = new FormData();
                    formData.append('file', file);
                    formData.append('type', type);
                    const endpoint = companies.uploadAsset();
                    const res = await that.$api.post(endpoint, formData);
                    if (res.data.success) {
                        let response = await that.geturl(res.data.s3_link);
                        if (type === 'company_logo') {
                            that.logoImages.push({
                                id: res.data.id,
                                file_id: res.data.s3_link,
                                image: response,
                            });
                            that.logoLoading = false;
                        } else if (type === 'company_digital_signage_image') {
                            that.screenSaverImages.push({
                                id: res.data.id,
                                file_id: res.data.s3_link,
                                image: response,
                            });
                            that.screenSaverLoading = false;
                        } else if (type === 'waiting_animation') {
                            that.waitingAnimations.push({
                                id: res.data.id,
                                file_id: res.data.s3_link,
                                image: response,
                            });
                            that.waitingAnimationLoading = false;
                        } else if (type === 'company_qr_logo') {
                            that.qrLogoImages.push({
                                id: res.data.id,
                                file_id: res.data.s3_link,
                                image: response,
                            });
                            that.qrLogoLoading = false;
                        } else {
                            that.backgroundImages.push({
                                id: res.data.id,
                                file_id: res.data.s3_link,
                                image: response,
                            });
                            that.backgroundLoading = false;
                        }
                    }
                };
                reader.onerror = function (error) {
                    this.logoLoading = false;
                    this.backgroundLoading = false;
                    this.screenSaverLoading = false;
                    this.qrLogoLoading = false;
                    this.waitingAnimationLoading = false;
                };
            },
            async geturl(fileId) {
                const endpoint = dashboardBuilder.getUrls();
                const requestData = {
                    fileIds: [fileId],
                };
                let response = await this.$api.post(endpoint, requestData);
                return response?.data?.data[0].image;
            },
            async getAllDefaultImages() {
                try {
                    const endpoint = companies.getDefaultAssetsList();
                    const assetsResponse = await this.$api.get(endpoint);

                    this.logoImages = assetsResponse?.data?.list.filter((item) => item.type === 'company_logo');

                    this.backgroundImages = assetsResponse?.data?.list.filter(
                        (item) => item.type === 'company_background_image'
                    );
                    this.waitingAnimations = assetsResponse?.data?.list.filter(
                        (item) => item.type === 'waiting_animation'
                    );
                    this.screenSaverImages = assetsResponse?.data?.list.filter(
                        (item) => item.type === 'company_digital_signage_image'
                    );
                } catch (err) {
                    this.$toasted.error('Failed to fetched Default Assets');
                }
            },
            async changeActiveStatus(company_id) {
                let ids = [];

                this.logoImages.forEach((image) => {
                    ids.push(image.id);
                });

                this.backgroundImages.forEach((image) => {
                    ids.push(image.id);
                });

                this.screenSaverImages.forEach((image) => {
                    ids.push(image.id);
                });

                this.waitingAnimations.forEach((image) => {
                    ids.push(image.id);
                });

                this.qrLogoImages.forEach((image) => {
                    ids.push(image.id);
                });

                const endpoint = companies.changeActiveStatus(company_id);
                const result = await this.$api.post(endpoint, { ids });

                if (!result.data.success) {
                    throw result.data.error;
                }
            },
            openTagModal(tag, index) {
                this.tagInfo = { ...tag };
                this.tagIndex = index;
                this.showTagModal = true;
            },
            saveTag(val) {
                if (this.tagIndex !== null) {
                    this.record_data.tags[this.tagIndex] = { ...val };
                } else {
                    this.record_data.tags.push(val);
                }
                this.$toasted.success(`Project Tag ${this.tagIndex !== null ? 'updated' : 'added'} successfully!`);
                this.closeTagModal();
            },
            removetag(index) {
                this.record_data.tags.splice(index, 1);
            },
            closeTagModal() {
                this.showTagModal = false;
                this.tagInfo = null;
                this.tagIndex = null;
            },
        },
        async created() {
            // This functionality is currently not in use
            // await this.getAllDefaultImages();
            this.record_data.tags = [];
            this.loading = false;
        },
    };
</script>

<style scoped>
    .color-box {
        width: 45% !important;
        border-radius: 10px;
        background: #fff;
        box-shadow: 0px 20px 20px 0px rgba(0, 0, 0, 0.12);
    }

    .other-btn {
        background: #fff;
        box-shadow: 0px 8px 22px 6px rgba(0, 0, 0, 0.24), inset 0px 4px 4px rgba(220, 220, 220, 0.25);
        color: #5057c3;
        width: 200px;
        height: 50px;
        font-family: Roboto;
        font-size: 16px;
        border-radius: 5px;
        border: 1px solid 5057C3;
        font-weight: 500;
    }

    .save-btn {
        background: #5057c3;
        box-shadow: 0px 8px 22px 6px rgba(0, 0, 0, 0.24), inset 0px 4px 4px rgba(220, 220, 220, 0.25);
        color: white;
        width: 200px;
        height: 50px;
        font-family: Roboto;
        font-size: 16px;
        border-radius: 5px;
    }

    .upload-section {
        margin: 0 15% 0 0;
        border-radius: 10px;
        border: 2px solid var(--grey, #7d7d7d);
        background: #fff;
        box-shadow: 0px 20px 5px 0px rgba(0, 0, 0, 0.03);
        cursor: pointer;
    }

    .scroll-Wrapper {
        width: 32vw;
        margin-bottom: 5%;
    }

    .upload-btn {
        border-radius: 6px;
        background: #5155c3;
        color: #fff;
        font-family: 'Roboto';
        box-shadow: 0px 20px 5px 0px rgba(0, 0, 0, 0.03);
    }

    .scroll-container {
        overflow: auto;
        white-space: nowrap;
        padding: 10px 0;
        display: flex;
        justify-content: flex-start;
    }

    .active-bg {
        width: 95px !important;
        height: 95px !important;
        border: 2px solid lime;
        padding: 2px;
    }
</style>
